import { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from "react-router-dom";
import dateFormat from "dateformat"
import PuffLoader from "react-spinners/PuffLoader";

import { GlobalStateContext } from '../Site.js'
import { Api } from '../API.js'
import { currencyFormat } from '../tools/NumberTools.js'
import { showToast } from '../tools/Toast.js'
import { showTidio } from '../tools/Tidio.js'

import CartItem from '../components/CartItem.js'
import GenerateInvoice from '../components/GenerateInvoice.js'

function OrderDetail(props) {
	
	const { globalState, setGlobalState } = useContext(GlobalStateContext);
	const [order, setOrder] = useState(null);
	const [openGenerateInvoice, setOpenGenerateInvoice] = useState(false);
	
	const { idOrder } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();
	
	const getOrder = () => {
		Api(
			"getCommandeById",
			{
				"id_commande": idOrder,
			},
			(data) => {
				setOrder(data);
			},
			(idError) => {
				showToast(t("toast.errorDisplayOrder"));
				navigate('/');
			},
			globalState.token,
			globalState.setToken
		);
	}
	
	const printOrderStatus = () => {
		let title = t("orderDetail.orderReceived");
		let subtitle = printScheduledDate();
		let icon = <i className="bi bi-rocket-takeoff"></i>;
		let color = "text-success";
		if((order.etats?.length > 0) && ((props.orderConfirmed == null) || (order.etats?.length > 1))) {
			let orderState = order.etats[0];
			let stateDate = new Date(orderState.date.date?.split(".")[0]?.replace(/-/g, "/"));
			switch(orderState.idEtatCommande) {
				case 3:
					title = t("orderDetail.statusPreparation");
					icon = <i className="bi bi-box2-heart-fill"></i>;
					break;
				case 12:
					title = t("orderDetail.statusScheduled");
					icon = <i className="bi bi-box2-heart-fill"></i>;
					break;
				case 15:
					title = t("orderDetail.statusVerification");
					icon = <i className="bi bi-box2-heart-fill"></i>;
					break;
				case 13:
				case 16:
					title = t("orderDetail.statusWaitingDriver");
					icon = <i className="bi bi-rocket-takeoff"></i>
					break;
				case 14:
				case 4:
					title = t("orderDetail.statusDelivery");
					icon = <i className="bi bi-car-front-fill"></i>;
					break;
				case 5:
					title = t("orderDetail.statusDelivered");
					subtitle = t("global.dateThe") + " " + dateFormat(stateDate, "dd/mm/yyyy") + " " + t("global.dateAt") + " " + dateFormat(stateDate, "HH:MM");
					icon = <i className="bi bi-check-circle-fill"></i>;
					break;
				case 6:
					title = t("orderDetail.statusCanceled");
					subtitle = t("orderDetail.statusSubtitleContact");
					icon = <i className="bi bi-x-circle-fill"></i>;
					color = "text-error";
					break;
				case 17:
				case 18:
				case 19:
				case 20:
					title = t("orderDetail.statusError");
					subtitle = t("orderDetail.statusSubtitleContact");
					icon = <i className="bi bi-x-circle-fill"></i>;
					color = "text-error";
					break;
			}
		}
		return(
			<>
				<h5 className={"fw-bold mb-1 " + color}>{icon}<span className="px-1"></span>{title}</h5>
				<p className="fw-normal">{subtitle}</p>
			</>
		);
	};
	
	const printScheduledDate = () => {
		if((order.datePrevue != null) && (order.datePrevue.date != null)) {
			let dateScheduled = new Date(order.datePrevue.date?.split(".")[0]?.replace(/-/g, "/"));
			let now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Zurich"}));
			let diffInMinutes = Math.round((dateScheduled.getTime() - now.getTime()) / 1000 / 60);
			if((diffInMinutes < 1440) && ((diffInMinutes <= 60) || (dateScheduled.getHours() > now.getHours()) || (dateScheduled.getHours() <= 6))) {
				return t("global.deliveryScheduled") + " " + t("global.dateAt") + " " + dateFormat(dateScheduled, "HH:MM");
			}
			else {
				return t("global.deliveryScheduled") + " " + dateScheduled.toLocaleDateString("fr-FR", { weekday: 'long' }) + " " + t("global.dateAt") + " " + dateFormat(dateScheduled, "HH:MM");
			}
		}
	};
	
	const printOrderDate = () => {
		if((order.dateFacture != null) && (order.dateFacture.date != null)) {
			let orderDate = new Date(order.dateFacture.date?.split(".")[0]?.replace(/-/g, "/"));
			return dateFormat(orderDate, "dd/mm/yyyy") + " " + t("global.dateAt") + " " + dateFormat(orderDate, "HH:MM");
		}
		return null;
	};
	
	const getNbProducts = () => {
		let nbProducts = 0;
		if((order.produits != null) && (Array.isArray(order.produits))) {
			order.produits.forEach((p, i) => {
				nbProducts = nbProducts + p.quantite;
			});
		}
		return nbProducts;
	};
	
	const getTotalProducts = () => {
		let total = 0;
		if((order.produits != null) && (Array.isArray(order.produits))) {
			order.produits.forEach((p, i) => {
				total = total + (p.produit.prixTTC * p.quantite);
			});
		}
		return total;
	};
	
	useEffect(() => {
		if((globalState != null) && (idOrder != null)) {
			getOrder();
		}
	}, [globalState.init, idOrder]);
	
	useEffect(() => {
		props.setReturnPage("/");
		props.setPageTitle(t("orderDetail.title"));
	}, []);
	
	return(
		<div className="vh-100 my-auto overflow-auto bg">
			{order != null &&
				<div className="row osahan-my-account-page border-secondary-subtle overflow-auto g-0">
					<div className="p-4 pb-0">
						{printOrderStatus()}
					</div>
					<div className="col-lg-8 p-4 border-end">
						<div className="card border rounded-4 bg-light">
						  <div className="card-header d-flex align-items-center justify-content-between py-3">
							 <div>
								<h6 className="fw-bold mb-1">{t("global.orderNum")} {order.idCommande}</h6>
								<p className="text-muted m-0">{t("orderDetail.orderDate", {date: printOrderDate()})}</p>
							 </div>
						  </div>
						  <div className="card-header d-flex flex-column justify-content-center py-3 gap-3">
							 <div className="d-flex align-items-center gap-3">
								<i className="bi bi-geo-alt-fill"></i>
								<div className="d-flex flex-column">
									<p className="text-muted m-0">{order.adresseLivraison.prenom + " " + order.adresseLivraison.nom}</p>
									<p className="text-muted m-0">{order.adresseLivraison.adresse + ", " + order.adresseLivraison.ville}</p>
								</div>
							 </div>
							 <div className="d-flex align-items-center gap-3">
								<i className="bi bi-telephone-fill"></i>
								<p className="text-muted m-0">{order.adresseLivraison.telephone}</p>
							 </div>
						  </div>
						  <div className="card-body">
							<h7 className="fw-bold mb-1">{order.produits.length} {order.produits.length > 1 ? t("global.items") : t("global.item")}</h7>
							<div className="cart-body">
								 {order.produits.map(p => {
										return <CartItem product={p.produit} quantity={p.quantite} userInteraction={false} key={p.produit.idProduit} />
								   })}
							</div>
							<div className="px-4 pt-4 bg-light cart-total m-0">
								<div className="d-flex align-items-center justify-content-between mb-1">
									<p className="text-muted m-0">{t("cart.total_products")} :</p>
									<p className="m-0">{currencyFormat(getTotalProducts())}</p>
								</div>
								<div className="d-flex align-items-center justify-content-between mb-1">
									<p className="text-muted m-0">{t("cart.total_delivery")} :</p>
									<p className="m-0">{currencyFormat(order.fraisLivraison)}</p>
								</div>
								<div className="d-flex align-items-center justify-content-between mb-1">
									<h6 className="fw-bold m-0">{t("cart.total")} :</h6>
									<p className="fw-bold text-info m-0">{currencyFormat(order.montantPaye)}</p>
								</div>
							</div>
						  </div>
						</div>
					</div>
					<div className="col-lg-4 card bg-light border-0 p-4">
						<div className="card-header py-3 rounded-4 border">
							<h6 className="fw-bold mb-4">{t("orderDetail.orderActions")}</h6>
							<div className="d-flex flex-column gap-3 px-4">
								<Link onClick={(e) => setOpenGenerateInvoice(true)} className={"fs-7 fw-bold " + (order.etats[0]?.idEtatCommande == 5 ? "text-info" : "text-muted")}><i className="bi bi-file-earmark-arrow-down-fill me-2"></i>{t("orderDetail.downloadInvoice")}<i className="bi bi-chevron-right ms-2 align-middle"></i></Link>
								<Link onClick={showTidio} className="text-info fs-7 fw-bold"><i className="icofont-user-alt-5 me-2"></i>{t("global.contactSupport")}<i className="bi bi-chevron-right ms-2 align-middle"></i></Link>
							</div>
						</div>
					</div>
					<GenerateInvoice isOpen={openGenerateInvoice} setIsOpen={setOpenGenerateInvoice} idOrder={idOrder} />
				</div>
		   }
		   {order == null &&
				<div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
					<PuffLoader 
						color="#1D71B8"
						size="5rem"
					/>
				</div>
		   }
		</div>
	);
}
export default OrderDetail;