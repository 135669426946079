import { useState, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from "react-router-dom";

import FAQItem from '../components/FAQItem.js'

import { showTidio } from '../tools/Tidio.js'

function FAQ(props) {
	
	const { t } = useTranslation();
	const items = t("faq.qa", { returnObjects: true });
	const [searchParams] = useSearchParams();
	const showAll = searchParams.get("showAll") != null ? true : false;
	
	useEffect(() => {
		props.setReturnPage("");
	}, []);
	
	return(
		<div className="vh-100 my-auto overflow-auto bg">
			<div className="px-4 py-3">
				<h5 className="offcanvas-title fw-bold">{t("faq.title")}</h5>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-lg-7 mx-auto px-4">
						{Object.keys(items).map((group, p) => {
							return(
								<div className="d-flex flex-column mb-3" key={p}>
									<span className="mb-3">{group}</span>
									<div className="accordion accordion-flush" id={"accordionFlushExample" + p}>
										{items[group].map((qa, i) => {
											return <FAQItem qa={qa} k={i} p={p} key={i} show={showAll} />
										})}
									</div>
								</div>
							)
						})}
						<div className="w-100 d-flex justify-content-center mt-5">
							<div className="p-3 rounded-4 shadow-sm bg-light-gray border d-flex align-items-center justify-content-centertext-center gap-3 mb-4 text-black">
								<div className="h-100 d-flex justify-content-center align-items-center border-end pe-3">
									<i className="bi bi-chat-left fs-4"></i>
								</div>
								<div className="d-flex align-items-center justify-content-center flex-column flex-grow-1 text-center">
									<h6>{t("faq.contactTitle")}</h6>
									<Link className="text-info mb-0" onClick={showTidio}>{t("faq.contactInfos")}</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default FAQ;