function NewsBar(props) {
	if(props.display !== "none") {
		return(
			<div className="homepage-navbar shadow px-3 py-2 mb-2" style={{backgroundImage: "linear-gradient(43deg, #f8f9fa -100%, #F0B600 100%)"}}>
				<div className="d-flex align-items-center justify-content-center">
					<i className="bi bi-clock me-3 fs-4"></i><h6 className="m-0 text-center">Nouveau ! Ouverture 7j/7 dès 17:00</h6>
				</div>
			</div>
		);
	}
}
export default NewsBar;