import { Link } from "react-router-dom";

function FAQItem(props) {
	return(
		<div className="accordion-item border rounded-4 overflow-hidden mb-3">
		   <h2 className="accordion-header" id={"flush-heading-" + props.p + "-" + props.k}>
			  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapse-" + props.p + "-" + props.k} aria-expanded="false" aria-controls={"flush-collapse-" + props.p + "-" + props.k}>
				  <span className="pe-2">{props.qa.q}</span>
			  </button>
		   </h2>
		   <div id={"flush-collapse-" + props.p + "-" + props.k} className={"accordion-collapse collapse" + (props.show === true ? " show" : "")} data-bs-parent={"#accordionFlushExample" + props.p}>
			  <div className="accordion-body border-top d-flex flex-column gap-2">
				{props.qa.a}
				{props.qa.l != null &&
					<Link {... props.qa.l} onClick={(e) => { if(props.qa.l.onClickVal) eval(props.qa.l.onClickVal); }} className="text-info">{props.qa.l.val} <i className="bi bi-chevron-right"></i></Link>
				}
			  </div>
		   </div>
		</div>
	);
}
export default FAQItem;