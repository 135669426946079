import React, { useState, createContext, useContext, useEffect } from 'react'
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next'
import Cookies from 'universal-cookie';
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";

import './vender/bootstrap/css/bootstrap.min.css'
import './vender/icofont/icofont.min.css'
import './vender/slick/slick/slick.css'
import './vender/slick/slick/slick-theme.css'
import './css/style.css'

import Home from './pages/Home.js'
import CartView from './pages/Cart.js'
import DeliveryAddress from './pages/DeliveryAddress.js'
import Payment from './pages/Payment.js'
import OrderDetail from './pages/OrderDetail.js'
import FAQ from './pages/FAQ.js'
import Error404 from './pages/Error404.js'
import Error from './pages/Error.js'
import Concept from './pages/Concept.js'
import FromCall from './pages/FromCall.js'
import Content from './pages/Content.js'
import NavBar from './components/NavBar.js'
import Footer from './components/Footer.js'
import Hours from './components/Hours.js'
import CookieBanner from './components/CookieBanner.js'

import { Api } from './API.js';
import Cart from './classes/Cart.js';
import UserLocation from './classes/UserLocation.js';
import { ToastContainerBottomCenter } from './tools/Toast.js';
import { checkEmail } from './tools/StringTools.js';
import ScrollToAnchor from './tools/ScrollToAnchor.js';

export const GlobalStateContext = createContext();

function Site() {
	
	const [globalState, setGlobalState] = useState(null);
	const [returnPage, setReturnPage] = useState(null);
	const [categories, setCategories] = useState([]);
	const [hours, setHours] = useState([]);
	const [pageTitle, setPageTitle] = useState(null);
	const [metaDescription, setMetaDescription] = useState(null);
	
	const { t } = useTranslation();
		
	const initGlobalState = () => {
		const cookies = new Cookies(null, { path: '/' });
		let token = cookies.get("token");
		const initialState = {
			init: true,
			token: null,
			setToken: (token) => {
				cookies.set("token", token, { expires: new Date(new Date().getTime()+(365*24*60*60*1000)) });
				setGlobalState(prevState => ({
					...prevState,
					token: token
				}));
			},
			customer: null,
			cart: Cart.emptyCart(),
			userLocation: null,
			loginPhone: null,
			cookiesAcceptanceVersion: cookies.get("cav") ?? 0,
			cookiesDisabled: false,
		};
		login(
			token,
			(newToken) => {
				cookies.set("token", newToken, { expires: new Date(new Date().getTime()+(365*24*60*60*1000)) });
				initialState.token = newToken;
				getCustomer(initialState, (currentState) => {
					let idCart = cookies.get("idCart");
					if(idCart != null) {
						Cart.getCart(
							idCart,
							currentState,
							(cart) => {
								let address = cart.getAddress(currentState);
								setGlobalState({
									...currentState,
									cart: cart,
								});
							},
							(idError) => {
								cookies.remove("idCart", { path: '/' });
								setGlobalState({
									...currentState,
									cart: Cart.emptyCart(),
								});
							}
						);
					}
					else {
						let address = null;
						if((currentState.customer != null) && (Array.isArray(currentState.customer.adresses)) && (currentState.customer.adresses.length > 0))
							address = currentState.customer.adresses[0];
					}
				});
			},
			(idError) => {
				setGlobalState(initialState);
			}
		);
	};
	
	const login = (token, onSuccess, onFailure) => {
		Api(
			"login",
			{
				"id_ville": process.env.REACT_APP_API_VILLE,
				"user": process.env.REACT_APP_API_USER,
				"password": process.env.REACT_APP_API_PASSWORD,
				"update": token
			},
			(data) => {
				onSuccess(data);
			},
			(idError) => {
				if(idError == 32) {
					login(null, onSuccess, onFailure);
				}
				else {
					onFailure(idError);
				}
			},
			null,
			null,
		);
	};
	
	const getCustomer = (initialState, then, afterLegacyAuth = false) => {
		Api(
			"getClientToken", 
			{},
			(data) => {
				initialState = {...initialState, customer: data };
				setGlobalState({
					...initialState,
				});
				then(initialState);
			},
			(idError) => {
				if((idError == 37) && !afterLegacyAuth) {
					getCustomerLegacy(initialState, then);
				}
				else {
					setGlobalState(initialState);
					then(initialState);
				}
			},
			initialState.token,
			initialState.setToken,
		);
	};
	
	const getCustomerLegacy = (initialState, then) => {
		axios
			.get(process.env.REACT_APP_LEGACY_AUTH_URL, { params: { token: initialState.token }, withCredentials: true })
			.then((response) => {
				if(process.env.REACT_APP_ENVIRONMENT !== 'production')
					console.log(response);
				if((response.data.error == 0) && (response.data.content == true)) {
					getCustomer(initialState, then, true);
				}
				else {
					setGlobalState(initialState);
					then(initialState);
				}
			})
			.catch((error) => {
				if(process.env.REACT_APP_ENVIRONMENT !== 'production')
					console.log(error);
				setGlobalState(initialState);
				then(initialState);
			})
	};
	
	const getCategories = () => {
		Api(
			"getCategories", 
			{
				"id_parent": process.env.REACT_APP_PARENT_CATEGORY,
				"incl_inactifs": 0,
				"with_products": 1,
			},
			(data) => {
				let categories = []
				data.forEach((c, i) => {
					if(c.produits?.length > 0)
						categories.push(c);
				});
				setCategories(categories);
			},
			(idError) => {
				setTimeout(() => getCategories, 1000);
			},
			globalState.token,
			globalState.setToken,
		);
	};
	
	const getHours = () => {
		Api(
			"getHoraires", 
			{},
			(data) => {
				setHours(data);
			},
			(idError) => {
			},
			globalState.token,
			globalState.setToken,
		);
	};
	
	useEffect(() => {
		if(globalState == null)
			initGlobalState();
	}, [globalState]);
	
	useEffect(() => {
		if((globalState != null) && (globalState.token != null)) {
			getCategories();
			getHours();
		}
	}, [globalState?.init]);
	
	useEffect(() => {
		if(globalState?.init) {
			let address = globalState?.cart?.getAddress(globalState);
			if((address == null) && ((globalState.userLocation?.idAddress ?? 0) == 0) && (globalState.customer != null) && (Array.isArray(globalState.customer.adresses)) && (globalState.customer.adresses.length > 0))
				address = globalState.customer.adresses[0];
			let userLocation = (address != null ? UserLocation.fromAddress(address) : (globalState.userLocation ?? UserLocation.defaultUserLocation()));
			setGlobalState(prevState => ({
				...prevState,
				userLocation: userLocation,
			}));
		}
	}, [globalState?.cart?.idAddress]);
	
	useEffect(() => {
		if(globalState?.init && (globalState.userLocation != null) && (globalState.userLocation.deliveryTime == null) && (globalState.userLocation.idError == 0)) {
			globalState.userLocation.getDeliveryTime(globalState, setGlobalState);
		}
	}, [globalState?.userLocation]);
	
	useEffect(() => {
		if(window.tidioChatApi) {
			if(globalState?.customer != null) {
				window.tidioChatApi.setVisitorData({
					distinct_id: globalState.customer.idClient,
					email: (checkEmail(globalState.customer.mail ?? "") ? globalState.customer.mail : ""),
					name: globalState.customer.prenom + " " + globalState.customer.nom,
					phone: "",
				});
			}
			if(globalState?.cookiesDisabled)
				window.tidioChatApi.addVisitorTags(['Cookies désactivés']);
		}
	}, [globalState?.customer, globalState?.cookiesDisabled]);
	
	useEffect(() => {
		/*Metamo*/
		var _mtm = window._mtm = window._mtm || [];
		_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
		var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
		g.async=true; g.src="https://matomo.sossoiree.fr/js/container_Ek4iXEgy.js"; s.parentNode.insertBefore(g,s);
		
		/*Tidio*/
		var t=d.createElement('script');
		t.async=true; t.src="//code.tidio.co/r4diqemt35m2e7rasbihd5a9xgsvf1zw.js"; s.parentNode.insertBefore(t,s);
		
		document.addEventListener("tidioChat-ready", () => {
			window.tidioChatApi.hide();
			window.tidioChatApi.on("close", () => {
				window.tidioChatApi.hide();
			});
			window.tidioChatApi.on("messageFromOperator", () => {
				window.tidioChatApi.show();
			});
		});
	}, []);

	if(globalState?.token != null) {
		return(
			<GlobalStateContext.Provider value={{globalState, setGlobalState}}>
				<BrowserRouter>
					<div className="Home bg-light d-flex flex-column vh-100">
						<NavBar returnPage={returnPage} categories={categories}/>
						<Routes>
							<Route path="/" element={<Home setReturnPage={setReturnPage} categories={categories} setPageTitle={setPageTitle} setMetaDescription={setMetaDescription} />} />
							<Route path="/view/*" element={<Home setReturnPage={setReturnPage} categories={categories} setPageTitle={setPageTitle} setMetaDescription={setMetaDescription} />} />
							<Route path={"/" + t("url.delivery") + "/:categoryName/:city/:idCategory/*"} element={<Home setReturnPage={setReturnPage} categories={categories} setPageTitle={setPageTitle} setMetaDescription={setMetaDescription} />} />
							<Route path={"/view/" + t("url.delivery") + "/:productName/:city/:idProduct"} element={<Home setReturnPage={setReturnPage} categories={categories} setPageTitle={setPageTitle} setMetaDescription={setMetaDescription} />} />
							<Route path={"/view/" + t("order.url")} element={<Home setReturnPage={setReturnPage} categories={categories} showOrder={true} setPageTitle={setPageTitle} setMetaDescription={setMetaDescription} />} />
							<Route path={"/view/" + t("hours.url")} element={<Home setReturnPage={setReturnPage} categories={categories} showHours={true} setPageTitle={setPageTitle} setMetaDescription={setMetaDescription} />} />
							<Route path={"/view/welcomelegacy"} element={<Home setReturnPage={setReturnPage} categories={categories} showWelcomeLegacy={true} setPageTitle={setPageTitle} setMetaDescription={setMetaDescription} />} />
							<Route path="cart/*" element={<CartView setReturnPage={setReturnPage} setPageTitle={setPageTitle} />} />
							<Route path="delivery/*" element={<DeliveryAddress setReturnPage={setReturnPage} setPageTitle={setPageTitle} />} />
							<Route path="payment/*" element={<Payment setReturnPage={setReturnPage} setPageTitle={setPageTitle} />} />
							<Route path="order/:idOrder/*" element={<OrderDetail setReturnPage={setReturnPage} setPageTitle={setPageTitle} />} />
							<Route path="order-confirmed/:idOrder/*" element={<OrderDetail setReturnPage={setReturnPage} setPageTitle={setPageTitle} orderConfirmed={true} />} />
							<Route path="faq/*" element={<FAQ setReturnPage={setReturnPage} setPageTitle={setPageTitle} />} />
							<Route path="concept/*" element={<Concept setReturnPage={setReturnPage} setPageTitle={setPageTitle} />} />
							<Route path="fromcall/:code?/*" element={<FromCall setReturnPage={setReturnPage} />} />
							<Route path="content/:cms" element={<Content setReturnPage={setReturnPage} />} />
							<Route path='*' element={<Error404 />} />
						</Routes>
						<Footer />
						<Hours hours={hours} />
						<CookieBanner />
						<ToastContainerBottomCenter />
						<ScrollToAnchor />
						<Helmet>
							<title>{pageTitle ?? t("global.pageRootTitle") + " - " + t("global.pageDefaultTitle")}</title>
							<meta name="description" content={metaDescription ?? t("global.metaDescription")} />
						</Helmet>
					</div>
				</BrowserRouter>
			</GlobalStateContext.Provider>
		);
	}
	else if(globalState?.init) {
		return (<Error />);
	}
	else {
		return(
			<div className="w-100 vh-100 d-flex align-items-center justify-content-center">
				<PuffLoader 
					color="#1D71B8"
					size="5rem"
				/>
			</div>
		);
	}
}

export default Site;
